import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.15_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_tvmrivfpdu6x6hrnnbbiksnsiq/node_modules/next/dist/client/components/render-from-template-context.js");
